// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import $ from "jquery";
import * as intlTelInput from 'intl-tel-input';
global.$ = $;
global.jQuery = $;

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('popper.js/dist/umd/popper')
require('bootstrap')
require('admin-lte')
require('jquery-timepicker/jquery.timepicker.js')
require('jquery-timepicker/jquery.timepicker.css')
require('bootstrap-datepicker')
require('moment')
require('daterangepicker')
require('daterangepicker/daterangepicker.css')
require('select2');
require('select2/dist/css/select2.css');
require('flatpickr');
require('flatpickr/dist/themes/dark');
require('@fullcalendar/core');
require('@fullcalendar/daygrid');
require('@fullcalendar/interaction');
require('@fullcalendar/timegrid');


$(document).ready(function(){

});

$( document ).on('turbolinks:load', function() {
  var input = document.querySelector("#restaurant_phone");
  intlTelInput(input, {
    separateDialCode:true,
    initialCountry: "auto",
    autoplaceholder: "off",
    hiddenInput: "phone",
    geoIpLookup: function(success, failure) {
    $.getJSON('https://api.db-ip.com/v2/free/self', function(resp) {
      var countryCode = (resp && resp.countryCode) ? resp.countryCode : "es";
      success(countryCode);
    });
  },
  utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js",
  });
  window.intlTelInputGlobals.getInstance(input).getNumber();
})
